
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n";
import UserAvatar from "@/components/UserAvatar.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Platform from "@/components/Platform.vue";
import FileViewer from "@/components/FileViewer.vue";
export default defineComponent({
  name: "kt-secretary-responses",
  props: {
    widgetClasses: String,
    clickedBot: {
      type: String,
      default: "",
    },
  },
  components: {
    UserAvatar,
    Platform,
    FileViewer,
  },
  setup(props) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };
    const loading = ref(false);
    const list = ref();
    const pagination = ref();
    const page = ref(1);
    const store = useStore();

    const getItems = () => {
      loading.value = true;
      ApiService.query("admin/secretary/list", {
        params: { page: page.value, with_user: true },
      })
        .then(({ data }) => {
          list.value = data.data.secretary_response_list.data;
          pagination.value = data.data.secretary_response_list.pagination;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const handleCurrentChange = (val: number) => {
      page.value = val;
      getItems();
    };

    onMounted(() => {
      getItems();
      setCurrentPageBreadcrumbs(translate("پیام‌های پاسخگوی‌خودکار"), ["management"]);
    });

    return {
      list,
      loading,
      pagination,
      handleCurrentChange,
      getItems,
      translate,
    };
  },
});
